import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Pagination, Slide } from "@mui/material";
import {
  Article,
  CloseSharp,
  Delete,
  FormatListBulleted,
  WidgetsOutlined,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { getUploadLogs } from "app/api/uploadLogsApi";
import { ErrorAlert, FileCard, SuccessAlert } from "app/components";
import { deleteUploadedFile } from "app/api/rollbackFileApi";
import { FileListItem } from "app/components/FileListItem/FileListItem";
import "./UploadedFiles.scss";
import { formatDate } from "app/utils/constants";
import { SuccessAlertReload } from "app/components/Alerts/SuccessAlertReload";

const FileCardWithRef = React.forwardRef((props, ref) => {
  return <FileCard ref={ref} {...props} />;
});

const UploadedFiles = () => {
  const defaultFileView = localStorage.getItem("file_view");
  const [isDetailsMenuOpen, setIsDetailsMenuOpen] = useState(false);
  const [selectedFileDetails, setSelectedFileDetails] = useState({});
  const [fileDetails, setFileDetails] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filesView, setFilesView] = useState(defaultFileView);
  const detailsMenuRef = useRef(null);
  const detailsMenuButtonRef = useRef(null);
  const pageSize = 10;
  const totalPages =
    totalDataCount === 0 ? 1 : Math.ceil(totalDataCount / pageSize);

  useEffect(() => {
    fetchUploadLogs(page);
    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !detailsMenuRef.current ||
        !detailsMenuRef.current.contains(event.target)
      ) {
        if (
          isDetailsMenuOpen &&
          !detailsMenuButtonRef.current.contains(event.target)
        ) {
          setIsDetailsMenuOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDetailsMenuOpen]);

  const fetchUploadLogs = async () => {
    try {
      const response = await getUploadLogs(page);
      setFileDetails(response.results);
      setTotalDataCount(response.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelection = (item) => {
    const itemEntries = Object.entries(item);
    const createdDate = formatDate(itemEntries[5][1]);
    const uploadMonth = itemEntries[3][1] < 10 ? `0${itemEntries[3][1]}` : "";
    const itemDetails = {
      slug: itemEntries[0][1],
      name: itemEntries[1][1],
      is_rolled_back: itemEntries[2][1],
      uploadMonth: uploadMonth,
      uploadYear: itemEntries[4][1],
      created: createdDate,
    };
    setSelectedFileDetails(itemDetails);
    setIsDetailsMenuOpen((prevState) => !prevState);
  };

  const handleRollback = async (selectedFileDetails) => {
    const { slug } = selectedFileDetails;
    try {
      Swal.fire({
        title: "¿Estás seguro que deseas eliminar esta carga de datos?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#63AEFE",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteUploadedFile(slug);
          setFileDetails((prevState) =>
            prevState.filter((item) => item.slug !== slug)
          );
          SuccessAlertReload("Carga de datos eliminada con éxito");
        }
      });
      setIsDetailsMenuOpen(false);
    } catch (error) {
      ErrorAlert("Error al intentar eliminar la carga de datos");
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilesView = (view) => {
    setFilesView(view);
    localStorage.setItem("file_view", view);
  };

  return (
    <Box className="uf">
      <Box className="uf-container">
        <Box className="uf-files-view">
          <Box className="uf-files-view__option">
            <Button onClick={() => handleFilesView("group")}>
              <WidgetsOutlined />
            </Button>
          </Box>
          <Box className="uf-files-view__option">
            <Button onClick={() => handleFilesView("list")}>
              <FormatListBulleted />
            </Button>
          </Box>
        </Box>
        {filesView === "group" ? (
          <Box className="uf-files">
            {fileDetails &&
              fileDetails.map((item, index) => (
                <FileCardWithRef
                  key={`file_${index}`}
                  filename={item.file_name}
                  created={item.created}
                  onClick={() => handleSelection(item)}
                  ref={detailsMenuButtonRef}
                />
              ))}
          </Box>
        ) : (
          <Box className="uf-list">
            <Box className="uf-list__titles">
              <h3 className="uf-list__titles-item">Tipo</h3>
              <h3 className="uf-list__titles-item">Nombre</h3>
              <h3 className="uf-list__titles-item">Fecha de creación</h3>
              <h3 className="uf-list__titles-item">Año de carga</h3>
              <h3 className="uf-list__titles-item">Mes de carga</h3>
              <h3 className="uf-list__titles-item">Eliminar</h3>
            </Box>
            {fileDetails &&
              fileDetails.map((item, index) => (
                <FileListItem
                  key={`file_${index}`}
                  filename={item.file_name}
                  created={item.created}
                  uploadMonth={item.upload_month}
                  uploadYear={item.upload_year}
                  onClick={() => handleRollback(item)}
                  className="uf-list__titles_item"
                />
              ))}
          </Box>
        )}
        <Box className="uf-pagination">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
            sx={{ "& .MuiPaginationItem-root": { fontSize: "1.5rem" } }}
          />
        </Box>

        <Box className="uf-menu" ref={detailsMenuRef}>
          <Slide
            direction="up"
            in={isDetailsMenuOpen}
            mountOnEnter
            unmountOnExit
          >
            <Box className="uf-menu-bottom" ref={detailsMenuRef}>
              <Box className="uf-menu-bottom__header">
                <Box className="uf-menu-bottom__header-item">
                  <button onClick={() => setIsDetailsMenuOpen(false)}>
                    <CloseSharp />
                  </button>
                </Box>
                <Box className="uf-menu-bottom__header-item">
                  <Article />
                  &nbsp;<p>Detalles del archivo</p>
                </Box>
              </Box>
              <Box className="uf-menu-bottom__body">
                <Box className="uf-menu-bottom__body-item">
                  <span>Nombre</span>
                  <p>&nbsp; {selectedFileDetails?.name}</p>
                </Box>
                <Box className="uf-menu-bottom__body-item">
                  <span>Tipo</span>
                  <p>Excel</p>
                </Box>
                <Box className="uf-menu-bottom__body-item">
                  <span>Mes de carga</span>
                  <p>&nbsp; {selectedFileDetails?.uploadMonth}</p>
                </Box>
                <Box className="uf-menu-bottom__body-item">
                  <span>Año de carga</span>
                  <p>&nbsp; {selectedFileDetails?.uploadYear}</p>
                </Box>
                <Box className="uf-menu-bottom__body-item">
                  <span>Modificado</span>
                  <p>&nbsp; {selectedFileDetails?.created}</p>
                </Box>
                <Box className="uf-menu-bottom__body-item">
                  <Button
                    variant="filled"
                    onClick={() => handleRollback(selectedFileDetails)}
                  >
                    <Delete /> Borrar carga
                  </Button>
                </Box>
              </Box>
            </Box>
          </Slide>
          <Slide
            direction="left"
            in={isDetailsMenuOpen}
            mountOnEnter
            unmountOnExit
          >
            <Box className="uf-menu-right" ref={detailsMenuRef}>
              <Box className="uf-menu-right__header">
                <Box className="uf-menu-right__header-item">
                  <Article />
                  &nbsp;<p>Detalles del archivo</p>
                </Box>
                <Box className="uf-menu-right__header-item">
                  <button onClick={() => setIsDetailsMenuOpen(false)}>
                    <CloseSharp />
                  </button>
                </Box>
              </Box>
              <Box className="uf-menu-right__body">
                <Box className="uf-menu-right__body-item">
                  <span>Nombre</span>
                  <p>&nbsp; {selectedFileDetails?.name}</p>
                </Box>
                <Box className="uf-menu-right__body-item">
                  <span>Tipo</span>
                  <p>Excel</p>
                </Box>
                <Box className="uf-menu-right__body-item">
                  <span>Mes de carga</span>
                  <p>&nbsp; {selectedFileDetails?.uploadMonth}</p>
                </Box>
                <Box className="uf-menu-right__body-item">
                  <span>Año de carga</span>
                  <p>&nbsp; {selectedFileDetails?.uploadYear}</p>
                </Box>
                <Box className="uf-menu-right__body-item">
                  <span>Modificado</span>
                  <p>&nbsp; {selectedFileDetails?.created}</p>
                </Box>
                <Box className="uf-menu-right__body-item">
                  <Button
                    variant="filled"
                    onClick={() => handleRollback(selectedFileDetails)}
                  >
                    <Delete /> Borrar carga
                  </Button>
                </Box>
              </Box>
            </Box>
          </Slide>
        </Box>
      </Box>
    </Box>
  );
};

export { UploadedFiles };
