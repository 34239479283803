import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { SimpleBackdrop } from "app/components/SimpleBackdrop/SimpleBackdrop";
import { setLoading } from "app/redux/slices/interface/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { ErrorAlert } from "..";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadInsuranceReport } from "app/api/reportsDataApi";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const { startDay, endDay, startMonth, endMonth, startYear, endYear } =
    useSelector((state) => state.dateform);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const query = event.target.value;
    setQuery(query);
  };

  const handleSearch = () => {
    onSearch(query);
  };

  const handleDownload = async (e) => {
    try {
      dispatch(setLoading(true));
      e.preventDefault();
      const response = await downloadInsuranceReport(
        query,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear
      );
      const blob = new Blob([response.file], {
        type: "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = response.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      dispatch(setLoading(false));
    } catch (error) {
      ErrorAlert("Error al descargar el reporte, intente más tarde");
      dispatch(setLoading(false));
      console.log(">>>>>>>>>>>", error);
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        border: ".1rem solid #a9a9a9",
        borderRadius: "2rem",
        margin: "2rem 0 3rem 0",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <Button sx={{ p: "1rem" }} aria-label="menu" onClick={handleSearch}>
        <SearchIcon sx={{ fontSize: "2rem" }} />
      </Button>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        onChange={handleChange}
        value={query}
        placeholder="Buscar"
      />
      <Button onClick={handleDownload}>
        <DownloadIcon sx={{ fontSize: "2rem" }} />
      </Button>
      <SimpleBackdrop />
    </Paper>
  );
};

export { SearchBar };
