import React, { useState } from "react";
import { SinisterTable } from "../tables/SinisterTable";
import { BillsTable } from "../tables/BillsTable";
import { BillPaymentsTable } from "../tables/BillPaymentsTable";
import { SearchBar } from "app/components/SearchBar/SearchBar";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Typography } from "@mui/material";
import {
  Add,
  AttachMoney,
  CarCrash,
  Delete,
  Description,
  Edit,
} from "@mui/icons-material";
import { FormDialog } from "app/components";
import "./Content.scss";
import { getInsuranceReports } from "app/api/reportsDataApi";
import { useSelector } from "react-redux";

const FirstTabContent = () => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const { startDay, endDay, startMonth, endMonth, startYear, endYear } =
    useSelector((state) => state.dateform);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = (mode) => {
    setMode(mode);
    setOpen(true);
  };

  const handleSearch = async (query) => {
    try {
      const results = await getInsuranceReports(
        query,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear
      );
      setSearchResults(results);
      setHasSearched(true);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };

  const titles = {
    edit: "Editar registro",
    add: "Crear registro",
    delete: "Eliminar registro",
  };

  const noResults =
    hasSearched &&
    (!searchResults ||
      !searchResults.results ||
      searchResults.results.length === 0);

  return (
    <Box className="rc">
      <Box className="rc-search">
        <SearchBar onSearch={handleSearch} />
      </Box>
      <Box className="rc-actions">
        <Button onClick={() => handleOpenDialog("add")}>
          <Add sx={{ fontSize: "2rem" }} />
        </Button>
        <Button onClick={() => handleOpenDialog("edit")}>
          <Edit sx={{ fontSize: "2rem" }} />
        </Button>
        <Button onClick={() => handleOpenDialog("delete")}>
          <Delete sx={{ fontSize: "2rem" }} />
        </Button>
      </Box>
      {noResults && (
        <Box className="rc-no-results">
          <Typography variant="h5" color="error" sx={{ textAlign: "center" }}>
            No se encontraron resultados que coincidan con el criterio de
            búsqueda o la fecha seleccionada.
          </Typography>
        </Box>
      )}
      {searchResults &&
        searchResults.results &&
        searchResults.results.length > 0 && (
          <>
            <Box className="rc-data">
              <h2>
                <CarCrash />
                Movimientos de Siniestros
              </h2>
              <SinisterTable data={searchResults.results} />
            </Box>
            <Box className="rc-data">
              <h2>
                <Description />
                Movimientos de Facturas
              </h2>
              <BillsTable data={searchResults.results} />
            </Box>
            <Box className="rc-data">
              <h2>
                <AttachMoney />
                Pago de Facturas
              </h2>
              <BillPaymentsTable data={searchResults.results} />
            </Box>
          </>
        )}
      <FormDialog
        open={open}
        handleClickOpen={handleOpenDialog}
        handleClose={handleClose}
        titles={titles}
        mode={mode}
      />
    </Box>
  );
};

export { FirstTabContent };
