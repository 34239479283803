import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataTable } from "app/components";
import { columnMapping, sinisterHeaders } from "app/utils/constants";
import "../content/Content.scss";

const SinisterTable = ({ data }) => {
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;
  const newHeaders = sinisterHeaders.map(header => columnMapping[header] || header);

  useEffect(() => {
    if (data) {
      setTotalDataCount(data.length);
      const totalPages = data.length === 0 ? 1 : Math.ceil(data.length / pageSize);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const filteredData = data ? data.slice(startIndex, endIndex).map((item) => {
    const filteredItem = {};
    sinisterHeaders.forEach((header) => {
      filteredItem[header] = item[header];
    });
    return filteredItem;
  }) : [];

  return (
    <Box className="rc-container">
      <Box className="rc-data" sx={{ padding: 0 }}>
        <DataTable
          headers={newHeaders}
          data={filteredData}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Box>
    </Box>
  );
};

export { SinisterTable };
