import React, { useEffect, useState } from "react";
import { PageTitle } from "app/components";
import { EditableTable } from "app/components/EditableTable/EditableTable";
import "./EditableIndicators.scss";
import { Box, Button } from "@mui/material";
import { Sos } from "@mui/icons-material";
import { GuideAlert } from "app/components/Alerts/GuideAlert";
import { getIndicators } from "app/api/indicatorApi";

const EditableIndicators = () => {
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    fetchIndicators();
  }, []);

  const fetchIndicators = async () => {
    try {
      const response = await getIndicators();
      setIndicators(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSos = () => {
    GuideAlert(
      "Instrucciones de uso",
      "Edita el campo, corrobora que los datos sean correctos y haz clic en el botón para guardar cambios."
    );
  };

  return (
    <Box className="ei">
      <Box className="ei-container">
        <Box className="ei-item">
          <Box className="ei-header">
            <PageTitle title="Indicadores" text="Edición de indicadores" />
          </Box>
          <Box className="ei-body">
            <Box className="ei-body-help" >
              <Button onClick={() => handleSos()}>
                <Sos sx={{ fontSize: "3rem" }} />
              </Button>
            </Box>
            <Box className="ei-table-wrapper">
              <EditableTable data={indicators} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { EditableIndicators };
