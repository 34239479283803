import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { formatDate } from "app/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setUpdatedData } from "app/redux/slices/data/indicatorsSlice";
import { SimpleBackdrop } from "../SimpleBackdrop/SimpleBackdrop";
import { updateIndicator } from "app/api/indicatorApi";
import { ErrorAlert, SuccessAlert } from "app/components";
import { SuccessAlertReload } from "../Alerts/SuccessAlertReload";

const EditableTable = ({ data }) => {
  const updatedData = useSelector((state) => state.indicators.updatedData);
  const [firstClicked, setFirstClicked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUpdatedData(data));
  }, [data, dispatch]);

  const handleSave = (index, value) => {
    value = value.trim();

    if (value.charAt(0) === "0" && !value.includes(".")) {
      const intValue = parseInt(value, 10);
      if (!isNaN(intValue)) {
        value = intValue.toString();
      }
    }
    if (index >= 0 && index <= 4 && isNaN(Number(value))) {
      ErrorAlert(
        "Los factores deben ser numéricos y no pueden contener doble punto."
      );
      return;
    }
    if (value.includes("..") || (value.match(/\./g) || []).length > 1) {
      ErrorAlert("¡Error! El valor no puede contener más de un punto.");
      return;
    }
    if (value.includes(".") && !/(^0\.|^[1-9][0-9]*\.)/.test(value)) {
      ErrorAlert(
        "Si hay un punto, solo puede haber un '0' antes o un número diferente de '0'."
      );
      return;
    }
    const newData = updatedData.map((item, i) => {
      if (i === index) {
        return { ...item, value };
      }
      return item;
    });
    dispatch(setUpdatedData(newData));
  };

  const handleSubmit = async () => {
    if (updatedData.some((indicator) => indicator.value.trim() === "")) {
      ErrorAlert(
        "No se permiten campos vacíos. Por favor, completa todos los campos."
      );
      return;
    }

    Swal.fire({
      title: "¿Estás seguro que deseas guardar los cambios?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#63AEFE",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setFirstClicked(true)
          await Promise.all(
            updatedData.map(async (indicator) => {
              const { slug, name, value } = indicator;
              await updateIndicator(slug, name, value);
            })
          );
        } catch (error) {
          console.log(error);
        } finally{
          setFirstClicked(false);
          SuccessAlertReload("Actualización realizada con éxito");
        }
      }
    });
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre de indicador</TableCell>
              <TableCell>Valor actual</TableCell>
              <TableCell>Últ. fecha de modificación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedData &&
              updatedData.map((row, index) => (
                <TableRow key={row.slug}>
                  <TableCell sx={{ fontSize: "1.2rem", minWidth: "20rem" }}>{row.name}</TableCell>
                  <TableCell
                    sx={{ padding: 0, display: "grid", placeItems: "center", minWidth: "15rem" }}
                  >
                    <TextField
                      sx={{ minWidth: "15rem", background: "#e9eaed" }}
                      value={row.value}
                      onChange={(e) => handleSave(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell sx={{ fontSize: "1.2rem", minWidth: "20rem" }}>
                    {formatDate(row.updated)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <SimpleBackdrop />
      </TableContainer>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          marginTop: "4rem",
          width: "100%",
        }}
      >
        <Button variant="filled" onClick={handleSubmit} disabled={firstClicked}>
          Guardar cambios
        </Button>
      </Box>
    </Box>
  );
};

export { EditableTable };
