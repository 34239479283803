import React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setCurrentTable, setToggleFetchTable } from "app/redux/slices/data/tablesSlice";
import { randomString } from "app/utils/constants";
import { PrebuiltItem, SuccessAlert } from "app/components";
import { setSelectedColumns } from "app/redux/slices/data/columnsSlice";
import { setSearchQuery } from "app/redux/slices/data/searchQuerySlice";
import { setReportType } from "app/redux/slices/data/reportTypeSlice";
import { downloadUpdateReportFormat } from "app/api/reportsDataApi";
import "./Content.scss";

const SecondTabContent = () => {
  const dispatch = useDispatch();

  const handleTableChange = (tableName) => {
    const randString = randomString(5);
    dispatch(setSearchQuery(""));
    dispatch(setCurrentTable(tableName));
    if (tableName === "finance") dispatch(setReportType("base_data"));
    else dispatch(setReportType(tableName));
    dispatch(setSelectedColumns([]));
    dispatch(setToggleFetchTable(randString));
    SuccessAlert("Reporte generado con éxito");
  };

  const handleDownloadFormat = async () => {
    try {
      const response = await downloadUpdateReportFormat();
      const blob = response.file;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = response.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box className="rc-container" sx={{ width: "100%" }}>
      <Box className="rc-label" sx={{ width: "100%", marginTop: "3rem" }}>
        <span
          style={{
            color: "#63AEFE",
            fontSize: "1.4rem",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          Elige un reporte prefabricados
        </span>
      </Box>
      <Box className="rc-prebuilt" sx={{ margin: "3rem 0 5rem 0" }}>
        <Box
          className="rc-prebuilt-headers"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            margin: "1rem 0",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.1rem",
              textTransform: "uppercase",
              width: "20rem",
            }}
          >
            Reporte
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.1rem",
              textTransform: "uppercase",
              width: "20rem",
            }}
          >
            Descripción
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.1rem",
              textTransform: "uppercase",
              width: "20rem",
            }}
          >
            Acción
          </Typography>
        </Box>
        <PrebuiltItem
          title="Contabilidad"
          desc="Contiene la estructura base de reportes de contabilidad."
          generate={() => handleTableChange("contability")}
          download={() => handleDownloadFormat()}
        />
      </Box>
    </Box>
  );
};

export { SecondTabContent };
