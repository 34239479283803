import { capitalize } from "@mui/material";

export const avatarColors = [
      "#64AEFE"
  // "#49243E",
  // "#222831",
  // "#344955",
  // "#12372A",
  // "#1F2544",
  // "#561C24",
  // "#7E6363",
  // "#164863",
  // "#3D0C11",
  // "#352F44",
  // "#79155B",
  // "#A75D5D",
];

export const getCookie = (name) => {
  const cookieValue = document.cookie.match(
    "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  const value = cookieValue ? cookieValue.pop() : "";
  return value;
};

export const getSessionId = () => {
  let session_id = getCookie("sessionid");
  if (!session_id || session_id === "") {
    console.log("No hay una sesion activa, redireccionando a login");
  } else {
    return session_id;
  }
};

export const randomString = (len) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randStr = "";
  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * characters.length);
    randStr += characters.charAt(index);
  }
  return randStr;
};

export const formatMonth = (value) => {
  const strValue = String(value);
  if (value < 10 && strValue.length === 1) {
    return `0${strValue}`;
  } else {
    return strValue;
  }
};

export const formatDate = (created) => {
  const date = new Date(created);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const createdDate = `${formatMonth(day)}-${formatMonth(month)}-${year}  ${hour}:${minutes} hrs.`;
  return createdDate;
}

export const avatarLetters = ( name, lastname ) => {
    const firstNameLetters = (
    name.slice(0, 1) + lastname.slice(0, 1)
  ).toUpperCase();

  return firstNameLetters;
}

export const shortName = (name, lastname) => {
  const newName = capitalize(name.split(/\s+/)[0]) + " " + capitalize(lastname.split(/\s+/)[0]);
  return newName;
}

export const columnMapping = {
  concesionario_id: "Clave distribuidor",
  concesionario: "Distribuidor",
  plan_financiero_id: "Clave plan financiero",
  plan_financiero: "Plan financiero",
  numero_chasis: "Número de chasis",
  modelo: "Modelo",
  original_principal_date: "Fecha",
  original_principal_amount: "Valor unidad",
  total_cuota_seguro: "Total cuota seguro",
  factor_millar: "Factor Millar",
  factor_pago_aseguradora: "Factor pago aseguradora",
  IVA: "IVA",
  factor_gastos_asociacion: "Factor gastos asociación",
  cuota_asociacion: "Cuota asociación",
  cuota_convencion: "Cuota convencion",
  cuota_publicidad: "Cuota publicidad",
  cuota_capacitacion: "Cuota capacitacion",
  cuota_amda: "Cuota AMDA",
  total_cuotas: "Total cuotas",
  origen_traslado: "Origen traslado",
  origen_destino: "Origen destino",
  costo_traslado: "Costo traslado",
  costo_seguro: "Costo seguro",
  numero_siniestro: "Número de siniestro",
  distribuidora_reparadora: "Distribuidora reparadora",
  tipo_siniestro: "Tipo de siniestro",
  fecha_factura_aseguradora: "Fecha factura aseguradora",
  monto_factura_aseguradora: "Monto factura aseguradora",
  fecha_ingreso_aseguradora: "Fecha ingreso aseguradora",
  fecha_pago_aseguradora: "Fecha pago aseguradora",
  upload_month: "Mes subida",
  upload_year: "Año subida",
  id_poliza: "ID Póliza Seguro",
  vin: "VIN",
  cuota_seguro_financiera: "Cuota seguro financiera",
  prima_sin_iva: "Prima sin IVA",
  iva_prima: "Prima con IVA",
  prima_total: "Prima total",
  utilidad: "Utilidad",
  porcentaje_asociacion: "1% Porcentaje de asociación",
  remanente_distribuidor: "Remanente distribuidor",
  fecha_generacion_excel: "Fecha generación excel",
  distribuidor_propietario: "Distribuidor propietario",
  fecha_siniestro: "Fecha siniestro",
  reportado: "Reportado a",
  propietario: "Propietario",
  concepto: "Concepto",
  lugar: "Lugar",
  chofer: "Chofer",
  fecha_valuacion: "Fecha valuación",
  cia: "CIA",
  ajustador: "Ajustador",
  factura: "Factura",
  fecha_factura: "Fecha Reporte",
  reparador: "Quién reparó",
  ocurrido: "Ocurrido",
  ga: "GA",
  gai: "GAI",
  recupero: "Recupero",
  salvamento: "Salvamento",
  rc: "RC",
  total: "Total",
  cheque: "Cheque",
  fecha_pago_factura: "Fecha pago factura",
  banco: "Banco",
  monto: "Monto",
  reportado_a: "Reportado A",
  fecha_reporte: "Fecha reporte",
};

export const getFilenameFromHeaders = (headers) => {
  const contentDispositionHeader = headers["content-disposition"];
  const filenameMatch = contentDispositionHeader?.match(
    /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  );
  if (filenameMatch && filenameMatch.length > 1) {
    return filenameMatch[1].replace(/['"]/g, "");
  } else {
    return "file";
  }
};

export const sinisterHeaders = [
  "vin",
  "poliza_seguro",
  "numero_siniestro",
  "fecha_siniestro",
  "reportado",
  "propietario",
  "concepto",
  "lugar",
  "chofer",
  "fecha_valuacion",
  "cia",
  "ajustador",
  "factura",
]

export const billsHeaders = [
  "vin",
  "poliza_seguro",
  "numero_siniestro",
  "factura",
  "fecha_factura",
  "reparador",
  "ocurrido",
  "ga",
  "gai",
  "recupero",
  "salvamento",
  "rc",
  "total",
]

export const billPaymentsHeaders = [
  "vin",
  "poliza_seguro",
  "factura",
  "cheque",
  "fecha_pago_factura",
  "banco",
  "monto",
  "reportado_a",
  "fecha_reporte",
]